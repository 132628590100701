import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledContainer = styled(Container)`
  max-width: 1100px;
`

const StyledProductDetails = styled.section`
  padding: 90px 0 50px;
  text-align: center;

  ${breakpoint.small`
	padding: 90px 0 20px;
  `}

  ${breakpoint.medium`
    padding: 140px 0 50px;
  `}

    h2 {
      padding-bottom: 3rem;

		${breakpoint.medium`
			padding-bottom: 5rem;
		`} d
    }

    p {
      line-height: 1.6em;
    }
  

  .product-details__details {
    position: relative;
    padding: 60px 0;

    ${breakpoint.medium`
      padding: 132px 0;
    `}
  }

  
.tab { 
	font-size: 16px;
	display: flex;
	column-count: 2;
	justify-content: center;
  	text-align: center;

     ${breakpoint.medium`
		font-size: 22px;
		margin: 0 2px;
    `}
 }
  
  .tablinks {
  	text-align: center;
  	color: ${colors.charcoal}; 
  	cursor: pointer;
	padding-bottom: 20px;
	width: 100%;
	border-bottom: 6px solid ${colors.white};

		&:hover {
			color: ${colors.purpleDark}; 
		}

		&.active, &:focus {
			color: ${colors.purpleDark}; 
			border-bottom: 6px solid ${colors.purpleDark};
		}
  	  }

	
	.detail {
		border-top: 1px solid ${colors.charcoal};
		padding-top: 40px;
		display: none;
	}
	
	.visible { display: block; }

	.citation { 
		display: none;
		font-size: 14px; 
		color: ${colors.gray};
		text-align: center;
		margin-top: 3rem;

		${breakpoint.medium`
		  text-align: left;
		`}
	}

	.styleddetail, .grid-styleddetail {
		align-content: start;
		justify-content: center;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin: 0 auto;
		z-index: 1;
		text-align: center;
		min-height: 300px;

	  ${breakpoint.small`
	  justify-content: start;
		flex-wrap: no-wrap;
		text-align: left;
		padding: 0 20px;
	  `}

	.detail__image {
		width: 100%; 
		margin: 30px auto 0;
		padding: 50px;

		${breakpoint.small`
		  	margin: 0;
		  	padding: 0;
		`}
	}
	
	.details__content {
		display: flex;
		justify-content: center;
		align-content: center;
		width: 100%;
		column-gap: 5%;
		flex-direction: row;
		
		${breakpoint.small`
			column-gap: 20%;
		`}
	}
	
	.details__image {
		width: 100%;
		max-width: 250px; 
		margin: 0 auto;
		padding-bottom: 10px;

		${breakpoint.small`
			max-width: 350px; 
			width: 100%;
		  	margin: 0;
		`}
		
		img {
			object-fit: contain!important;
		}
	}

	.profile {
		max-width: 150px!important; 
		margin-top: 40px;

		${breakpoint.small`
			margin-top: 0;
			max-width: 200px!important; 
		`}

	}

	  .detail__content {
		width: 100%;
		margin: 0 auto 40px;

		${breakpoint.small`
			margin: 0px auto 40px;
			width: 50%;		
		`}

		${breakpoint.medium`
		  	width: 50%;
		`}
	
			a {
			color: ${colors.purpleDark};
		
			&:hover {
				color: ${colors.orange};
			}
		 }
	  }
	  
	  .detail__content-grid {
		width: 100%;
		margin: 0;

		${breakpoint.medium`
		  margin: 0 40px 20px;
		`}
	  
	  }
  
		 
		.grid-container {
			display: grid;
			line-height: 1.2em;
			font-size: 13px;

			${breakpoint.xs`
				grid-template-columns: auto 31% 26% 25%;
				padding: 10px;
				font-size: 14px;
			`}			
		}

		.grid-container > div {
			border: ${colors.gray} 1px solid;
			padding: 2px;

			${breakpoint.small`
				padding: 10px 5px;
			`}

		}

		.grid-title {
			font-size: 14px;

			&--top {
				background-color: ${colors.grayLight}; 
				font-style: italic;
				font-weight: 600;
			}

			&--side {
				background-color: ${colors.purpleLight}; 
				font-weight: 600;
			}
		}

		.sub {
			font-style: italic;
			line-height: 1.1em;
			margin: 10px auto;
			text-align: center;
			font-size: x-small;
			color: ${colors.graySelect};

			${breakpoint.medium`
			  font-size: small;
			`}
		}


	}
	
		.grid-styleddetail {
		  	justify-content: center;
		 }

`

const ProductDetails = props => {

	return (
		<StyledProductDetails>
			<StyledContainer data-aos="fade-up" data-aos-once="false">
			{ props.titleText ? ( <h2>{props.titleText}</h2> ) : <h2>Product details</h2> }
				<div className="tab">
					<button
						type="button"
						data-filter
						data-filter-type="detail"
						data-target="about"
						className="tablinks active"
						onClick={() => props.filterFunction("detail", "about")}
						data-aos="fade-up"
						data-aos-once="false"
					>
						About
					</button>
					<button
						type="button"
						data-filter
						data-filter-type="detail"
						data-target="size"
						className="tablinks"
						onClick={() => props.filterFunction("detail", "size")}
						data-aos="fade-up"
						data-aos-once="false"
					>
						Size
					</button>
					<button
						type="button"
						data-filter
						data-filter-type="detail"
						data-target="accuracy"
						className="tablinks"
						onClick={() => props.filterFunction("detail", "accuracy")}
						data-aos="fade-up"
						data-aos-once="false"
					>
						Accuracy
					</button>
				</div>

				<div className="detail visible" data-filter-type="detail" data-filter="about" data-aos="fade-up" data-aos-once="false">
					<div className="styleddetail">
						<StaticImage
							src="../../../assets/images/respiratory-health-test/device.png"
							width={300}
							layout="fixed"
							alt="Visby Medical Respiratory Health Test"
							className="detail__image"
						/>
						<div className="detail__content">
							<p>True PCR results that detect and differentiate RNA from the viruses that cause flu and COVID-19, 
								in under 30 minutes with lab accuracy in the palm of your hands.</p>
						</div>
					</div>
				</div>

				<div className="detail" data-filter-type="detail" data-filter="size">
					<div className="styleddetail">
						<div className="details__content">
							<StaticImage
								src="../../../assets/images/respiratory-health-test/details/device-face-new.png"
								width={330}
								placeholder="tracedSVG"
								alt="Visby Medical Respiratory Health Test face measurements"
								className="details__image"
							/>
							<StaticImage
								src="../../../assets/images/respiratory-health-test/details/device-profile.png"
								width={300}
								placeholder="tracedSVG"
								alt="Visby Medical Respiratory Health Test profile measurements"
								className="details__image profile"
							/>
						</div>
					</div>
				</div>

				<div className="detail" data-filter-type="detail" data-filter="accuracy">
					<div className="grid-styleddetail">
						<div className="detail__content-grid">
							<div className="grid-container">
								<div className="grid-title--side">Limit of Detection (LOD)</div>
								<div className="grid-title--top">Influenza A</div>
								<div className="grid-title--top">Influenza B</div>
								<div className="grid-title--top">SARS-CoV-2</div>
								<div className="grid-title--side">Nasopharyngeal Swab</div>
								<div className="grid-title--text">Influenza A/HINI 2009, Brisbane/02/18<br />106 copies/ swab<br /><br />
								Influenza A/H3N2, Kansas/14/2017<br />125 copies/ swab</div>
								<div className="grid-title--text">Influenza B/Washington/02/19<br />728 copies / swab<br /><br />
								Influenza B/Oklahoma/10/2018<br />778 copies / swab</div>
								<div className="grid-title--text">SARS-CoV-2 (USA-WA1/2020)<br />100 copies swab</div>
								<div className="grid-title--side">Positive Percent Agreement (PPA)</div>
								<div>97.9%</div>
								<div>96.9%*</div>
								<div>97.4%</div>
								<div className="grid-title--side">Negative Percent Agreement (NPA)</div>
								<div>99.3%</div>
								<div>100%*</div>
								<div>98.8%</div>
							</div>
							<p className="sub">* Data is a combination of prospective fresh specimens (NP and AN), banked specimens (NP), and surrogate specimens (NP).</p>
						</div>
					</div>
				</div>
			</StyledContainer>
		</StyledProductDetails>
	)
}

class Final extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: "about"
		}
	}

	componentDidMount() {
		var filtby = this.state.filter;
		if (this.props.filterby)
			filtby = this.props.filterby;

		this.handleFilterChange("details", filtby, null);
	}

	handleFilterChange = (type, value, event) => {
		const inactiveFilters = document.querySelectorAll("[data-filter]")
		inactiveFilters.forEach(filter => {
			filter.classList.remove("active")
		})

		const activeFilter = document.querySelector(
			"[data-filter][data-target='" + value + "']"
		)
		activeFilter.classList.add("active")


		const previousElements = document.querySelectorAll(
			"[data-filter-type='" + type + "']"
		)
		previousElements.forEach(post => {
			post.classList.remove("visible")
		})

		const elementsToFilter = document.querySelectorAll(
			"[data-filter-type='" + type + "'][data-filter='" + value + "']"
		)
		elementsToFilter.forEach(post => {
			post.classList.add("visible")
		})


		const changeHero = document.querySelectorAll('.hover--image')
		changeHero.forEach(filter => {
			filter.classList.toggle("visible")
		})


	}


	handleToggleChange = (type, value, event) => {

		const activeFilters = document.querySelectorAll(
			"[data-filter][data-target='" + value + "']"
		)
		activeFilters.forEach(filter => {
			filter.classList.toggle("active")
		})
	}


	render = props => (
		<React.Fragment>
			<ProductDetails titleText={this.props.titleText} filterFunction={this.handleFilterChange} toggleFunction={this.handleToggleChange} />
		</React.Fragment>
	)
}

export default Final
